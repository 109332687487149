<template>
  <div class="home-teacher" >
    <div v-if="info.user">
      <div class="cont mt10" >
        <p class="tit">您好！ {{ info.user.nickname }}，欢迎登录{{ info.user.site.name }}！</p>
        <div class="data-info">
          <div class="data-info-r-t">
            <div class="data-info-exam app-bj-color border-left-red">
              <img src="../../assets/images/new_icon/production.png" width="48" height="48" />
              <div class="data-info-exam-value">
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.userTaskAmount || 0 }}</p>
                  <p>累计任务数</p>

                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.userTaskCount || 0 }}</p>
                  <p>可执行任务数</p>
                </div>
              </div>
            </div>
            <div class="data-info-exam ml20 app-bj-color border-left-yellow">
              <img src="../../assets/images/new_icon/homework_icon.png" width="48" height="48" />
              <div class="data-info-exam-value">
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.userDataAmount || 0 }}</p>
                  <p>累计作业数</p>
                </div>
                <div class="app-fgx"></div>
                <div class="data-info-exam-data">
                  <p class="text-color">{{ info.userDataCount || 0 }}</p>
                  <p>可执行作业数</p>
                </div>
              </div>
            </div>
          </div>
          <div class="data-info-r-b app-bj-color">
            <div class="data-info-r-b-num">
              <p></p>
              <p>累计作业时长</p>
              <div>
                <div v-if="info.userWorkTime.days"><span>{{info.userWorkTime.days}}</span>天</div>
                <div v-if="info.userWorkTime.hours"><span>{{info.userWorkTime.hours}}</span>小时</div>
                <div v-if="info.userWorkTime.minutes"><span>{{info.userWorkTime.minutes}}</span>分</div>
                <div><span>{{info.userWorkTime.seconds || 0}}</span>秒</div>
              </div>
            </div>
            <div class="data-info-r-b-num">
              <p></p>
              <p>驳回作业数</p>
              <p>{{ info.userHasRefusedCount || 0 }}</p>
            </div>
            <div class="data-info-r-b-num">
              <p></p>
              <p>挂起作业数</p>
              <p>{{ info.userHasDifficultCount || 0 }}</p>
            </div>
            <div class="data-info-r-b-num">
              <p></p>
              <p>返工作业数</p>
              <p>{{ info.userHasRefuseCount || 0 }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="cont mt10">
        <div class="cont-tit">作业量统计</div>
        <div id="workStatistics" class="chart-content"></div>
      </div>
    </div>
    <div class="no-data" v-else>
      <Spin fix></Spin>
    </div>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "teacherStat.vue",
  data(){
    return{
      info:{},
      pageStatus:true,
    }
  },
  mounted(){
    this.getData();
  },
  beforeDestroy(){
    this.pageStatus = false;
  },
  methods:{
    getData(){
      this.api.home.indexContentProduction().then((res)=>{
        this.info = res;
        this.info.userWorkTime = util.timeFormate(this.info.userWorkTime);
        this.$nextTick(()=>{
          if(this.pageStatus){
            this.getOption();
          }
        })
      })
    },
    getOption(){
      var chartDom = document.getElementById('workStatistics');
      var myChart = this.$echarts.init(chartDom);
      var option;

      let names = [];
      let counts = [];
      this.info.userFinishByMonth.forEach((res)=>{
        names.push(res.table_suffix);
        counts.push(res.amount);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {

        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '作业量统计',
            type: 'line',
            stack: 'Total',
            data: counts
          }
        ]
      };

      option && myChart.setOption(option);

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })
    },
    goTask(){
      this.$router.push({
        path:'/production/task'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.home-teacher{
  margin: 20px;
  font-size: 14px;
  .cont{
    background: #FFFFFF;
    padding: 20px;
    .cont-tit{
      font-weight: bold;
      font-size: 16px;
    }
  }
  .tit{
    margin-bottom: 18px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
  }
  .quick-entrance{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .quick-entrance-item{
      padding: 0 20px 0 12px;
      margin-right: 25px;
      flex: 1;
      height: 50px;
      border: 1px solid #E7E7E7;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      >div{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >img{
          width: 34px;
          height: 34px;
        }
      }
    }
    .quick-entrance-item:last-child{
      margin-right: 0;
    }
  }
  .data-info{
    .data-info-r-t{
      display: flex;
      justify-content: flex-start;

      .data-info-exam{
        padding:15px 20px;
        flex: 1;
        border-radius: 6px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #666666;
        height: 92px;
        .data-info-exam-value{
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex: 1;
          height: 100%;
        }

        .data-info-exam-data{
          >p:first-child{
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }
    .data-info-r-b{
      margin-top: 14px;
      padding: 25px 20px;
      display: flex;
      justify-content: flex-start;
      background: #ECF4FA;
      border-radius: 6px;
      color: #666666;

      .data-info-r-b-num{
        flex: 1;
        margin-left: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >p:first-child{
          margin-right: 6px;
          width: 8px;
          height: 8px;
          background: #333333;
        }
        >p:last-child{
          margin-left: 7px;
          //margin-bottom: 2px;
          font-size: 20px;
          font-weight: 400;
          color: #333333;
        }
        >div{
          margin-left: 7px;
          font-size: 20px;
          font-weight: 400;
          color: #333333;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
  }
  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }
      .search-cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .ml20{
    margin-left: 20px;
  }
  .mb20{
    margin-bottom: 20px;
  }
  .chart-content{
    width: 100%;
    height: 400px;
  }
  .no-data{
    position: relative;
    width: 100%;
    height: 800px;
  }
}

</style>
